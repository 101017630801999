import React, { useEffect, useState } from "react";
import { Mutation, Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { Loader, Modal, Button as MButton } from "semantic-ui-react";
import RegisterToApplyButton from "./RegisterToApplyButton";
import Button from "./Button";
import appText from "@/lang/appText";
import { useRouter } from "next/router";
import { getJob } from "@/lib/backend";
import { FaSpinner } from "react-icons/fa";

const APPLY_TO_JOB_MUTATION = gql`
  mutation APPLY_TO_JOB_MUTATION($jobId: ID!) {
    createApplication(job: $jobId) {
      id
      job {
        id
        title
      }
    }
  }
`;

export const CHECK_USER_APPLICATION_STATUS_QUERY = gql`
  query CHECK_USER_APPLICATION_STATUS_QUERY($jobId: String!) {
    me {
      id
      role {
        id
        name
      }
      applications(where: { job: { id: { equals: $jobId } } }) {
        id
      }
      resumes {
        id
      }
    }
  }
`;

const ApplyToJobButton = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const router = useRouter();
  return (
    <Query
      query={CHECK_USER_APPLICATION_STATUS_QUERY}
      variables={{ jobId: props.jobId }}
    >
      {({ error, loading, data }) => {
        if (loading) return <Loader active inline="centered" />;
        if (error) console.log(error);
        if (error) return <p>Something went wrong</p>;
        if (data.me && data.me.role.name !== "candidate") return null;

        //Check if the user has previously applied
        if (!data.me || data.me.resumes.length === 0)
          return <RegisterToApplyButton jobId={props.jobId} />;

        let userApplied = data.me.applications.length > 0;

        return (
          <React.Fragment>
            <Mutation
              mutation={APPLY_TO_JOB_MUTATION}
              variables={{ jobId: props.jobId }}
            >
              {(applyToJobMutation, { loading, error, called, data }) => {
                if (loading)
                  return (
                    <Loader active inline="centered">
                      Applying...
                    </Loader>
                  );
                if (error)
                  return <p>Something Failed, please try again later.</p>;

                if (data) {
                  //Used to disable the button after user applies
                  userApplied = !!data.createApplication.job;
                }

                return (
                  <>
                    <Modal
                      trigger={
                        <Button
                          // onClick={applyToJobMutation}
                          fullWidth
                          disabled={userApplied || loading}
                          loading={loading}
                        >
                          {userApplied
                            ? `${appText.messages.application.applied} 😊`
                            : `${appText.messages.application.applyNow}`}
                        </Button>
                      }
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                      onOpen={() => setOpenModal(true)}
                    >
                      <Modal.Header>Confirmation!</Modal.Header>
                      <Modal.Content>
                        <p>
                          Are you sure you want to apply to this job in{" "}
                          <b>{props.location}?</b>
                        </p>
                      </Modal.Content>
                      <Modal.Actions>
                        <MButton
                          color="green"
                          onClick={() => {
                            console.log("Closing Modal and applying job!");
                            setOpenModal(false);
                            applyToJobMutation();
                            setRedirectModal(true);
                            // wait for 5 seconds before redirecting
                            setTimeout(() => {
                              router.push("http://exactstaff.com/register");
                            }, 5000);
                          }}
                        >
                          Yes
                        </MButton>
                        <MButton
                          color="red"
                          onClick={() => {
                            console.log("Closing Modal!");
                            setOpenModal(false);
                          }}
                        >
                          No
                        </MButton>
                      </Modal.Actions>
                    </Modal>

                    {/* show a modal telling the canditate we are redirecting them to our complete registration page if they haven't registered with us */}
                    <Modal
                      open={redirectModal}
                      onClose={() => setRedirectModal(false)}
                      onOpen={() => setRedirectModal(true)}
                    >
                      <Modal.Header>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>Redirecting!</div>
                        </div>
                      </Modal.Header>
                      <Modal.Content>
                        <p>
                          You need to complete your registration if you haven't
                          already. We are redirecting you to the registration
                          page.
                        </p>
                      </Modal.Content>
                    </Modal>
                  </>
                );
              }}
            </Mutation>
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default ApplyToJobButton;
