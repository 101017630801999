import { useState } from "react";
import { Card } from "semantic-ui-react";
import PopUpContext from "./PopUpContext";
import Title from "../Title";
import Button from "../Button";

const PopUp = props => {
  const [title, setTitle] = useState(props.title);

  return (
    props.show && (
      <PopUpContext.Provider value={{ title, setTitle }}>
        <PopupContent {...props} />
      </PopUpContext.Provider>
    )
  );
};

function PopupContent({ children, changeHandler }) {
  return (
    <PopUpContext.Consumer>
      {({ title, setTitle }) => (
        <div className="PopUp">
          <div className="Content">
            <Card>
              <Card.Header>
                <Title inline>{title}</Title>
                <Button
                  onClick={() => changeHandler(false)}
                  iconOnly
                  icon="close"
                  float={"right"}
                />
              </Card.Header>
              <Card.Content>{children}</Card.Content>
            </Card>
          </div>
          <style jsx>{`
            .PopUp {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.4);
              z-index: 999;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .PopUp :global(.card) {
              width: 100% !important;
              max-width: 500px;
            }

            .PopUp :global(.card .header) {
              margin: 10px;
            }

            .Content {
              z-index: 999;
              position: relative;
              height: 80vh;
              overflow: auto;
            }
          `}</style>
        </div>
      )}
    </PopUpContext.Consumer>
  );
}

PopUp.defaultProps = { title: "" };

export default PopUp;
