import Translator from "@/components/hoc/Translator";
import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import { Button, Input, Modal } from "semantic-ui-react";

const USER_SEND_JOB_TO_FRIEND_MUTATION = gql`
  mutation AddFriendShare($receiverEmail: String!, $jobId: ID!) {
    addFriendShare(receiverEmail: $receiverEmail, jobId: $jobId)
  }
`;

var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const SendToFriendButton = ({ jobId }) => {
  const [receiverEmail, setReceiverEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [addFriendShare, { data, loading, error }] = useMutation(
    USER_SEND_JOB_TO_FRIEND_MUTATION
  );

  const handleSendToFriend = async () => {
    if (!receiverEmail.match(validRegex)) {
      alert("Please enter a valid email address.");
      return;
    }
    const result = await addFriendShare({
      variables: {
        receiverEmail,
        jobId,
      },
    });
    alert(result.data.addFriendShare);

    closeModal();
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  const oneModal = () => {
    setOpenModal(true);
  };

  return (
    <div
    // style={{
    //   padding: "5px 10px",
    //   backgroundColor: "gray",
    //   color: "white",
    //   fontSize: "1.2em",
    //   borderRadius: "5px",
    //   cursor: "pointer",
    //   display: "flex",
    //   alignItems: "center",
    // }}
    >
      <Modal
        dimmer="blurring"
        open={openModal}
        onClose={closeModal}
        onOpen={oneModal}
        trigger={
          <Button
            color="yellow"
            content={<Translator>Send To a Friend</Translator>}
            icon="user outline"
            size="small"
            // on hover scale bigger
            style={{
              display: "flex",
              transition: "transform 0.2s",
              color: "black",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.15)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            onClick={oneModal}
            // style={{
            //   display: "flex",
            // }}
            // label={{
            //   basic: true,
            //   color: "grey",
            //   pointing: "left",
            //   content: count,
            //   size: "mini",
            // }}
          />
        }
      >
        <Modal.Header>
          <Translator>Send To a Friend</Translator>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Translator>
              <p>
                Send this job to a friend who might be interested in this
                opportunity.
              </p>
            </Translator>
            <Input
              loading={loading}
              icon="user outline"
              placeholder="Enter Email...."
              onChange={(e) => {
                setReceiverEmail(e.target.value);
              }}
              focus
              type="email"
            />
            <Button
              icon="send"
              content={<Translator>Send</Translator>}
              color="blue"
              style={{
                margin: "10px",
                display: "flex",
              }}
              onClick={handleSendToFriend}
            />

            {/* <p>
              <strong>NOTE:</strong> This feature is not yet implemented.
            </p> */}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default SendToFriendButton;
